import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useReactToPrint } from "react-to-print";
import MobileNav from "../../components/mobileNav/MobileNav";
import "./newAppointment.css";

const NewAppointment = (props) => {
  const { pendingLength } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  const [showRejectionPopup, setShowRejectionPopup] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState(null);

  const maxLength = 10;

  const url = `https://aircon-api.basilalasadi.com/api/maintenance/GetPendingRequests`;

  const getAllAppointments = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      await axios
        .post(url, {
          maintenanceAreaIds: maintenanceAreaIds,
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMoveDataToWaiting = async (item, condition) => {
    try {
      const parsedData = JSON.parse(JSON.stringify(item));
      const requestId = parsedData.RequestId;
      const seconed_url = `https://aircon-api.basilalasadi.com/api/maintenance/ProcessMaintenanceApproveOrReject`;
      await axios.post(seconed_url, {
        RequestId: requestId,
        ProcessingTypeId: condition,
      });
      toast.success("تم قبول الطلب بنجاح", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (setData) {
        setData(data.filter((d) => d.RequestId !== requestId));
      } else {
        console.warn(
          "setData function is not defined. Local data update skipped."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMoveDataToRejected = async (item, condition) => {
    if (!rejectionReason) {
      toast.error("يرجى كتابه سبب الرفض", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    try {
      // const parsedData = JSON.parse(JSON.stringify(item));
      // const requestId = parsedData.RequestId;
      const third_url = `https://aircon-api.basilalasadi.com/api/maintenance/ProcessMaintenanceApproveOrReject`;
      await axios.post(third_url, {
        RequestId: selectedRequestId,
        ProcessingTypeId: condition,
        ReasonRejecte: rejectionReason,
      });
      toast.success("تم رفض الطلب ", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      if (setData) {
        setData(data.filter((d) => d.RequestId !== item.RequestId));
      }
      setShowRejectionPopup(false);
      setRejectionReason("");
      setSelectedRequestId(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenRejectionPopup = (item) => {
    setSelectedRequestId(item.RequestId);
    setRejectionReason("");
    setShowRejectionPopup(true);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  useEffect(() => {
    getAllAppointments();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <MobileNav />
      <section className="newAppointment mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>طلبات الصيانه الجديده</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/newAppointment"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <>
            <div className="newAppointment-container">
              <div className="row">
                <div className="col-lg-2 firstCol">
                  <Sidebar pendingLength={pendingLength} />
                </div>
                <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col pt-4">
                  <h2 className="mb-4">طلبات الصيانه الجديده:</h2>
                  <div className="dataTable" ref={componentRef}>
                    <div className="titles">
                      {data.length > 0 ? (
                        data.map((item) => {
                          return (
                            <ul className="d-flex" key={item.RequestId}>
                              <div className="firstbox d-flex justify-content-between">
                                <div>
                                  <li>إسم العميل</li>
                                  <p>
                                    {item.CustomerName &&
                                    item.CustomerName.length > maxLength
                                      ? `${item.CustomerName.substring(
                                          0,
                                          maxLength
                                        )}...`
                                      : item.CustomerName}
                                  </p>
                                </div>
                                <div>
                                  <li>رقم الهاتف</li>
                                  <a
                                    href={`https://api.whatsapp.com/send?phone=+966${item.PhoneNumber.replace(
                                      /[^\d+]/g,
                                      ""
                                    )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                                    target="__blank"
                                  >
                                    {item.PhoneNumber}
                                  </a>
                                </div>
                                <div>
                                  <li>المدينه</li>
                                  <p>{item.City}</p>
                                </div>
                                <div>
                                  <li>المنطقه</li>
                                  <p>{item.Region}</p>
                                </div>
                                <div>
                                  <li>الحي</li>
                                  <p>
                                    {item.Neighborhood &&
                                    item.Neighborhood.length > maxLength
                                      ? `${item.Neighborhood.substring(
                                          0,
                                          maxLength
                                        )}...`
                                      : item.Neighborhood}
                                  </p>
                                </div>
                              </div>
                              <div className="seconedbox d-flex justify-content-between ">
                                <div>
                                  <li>المكيف</li>
                                  <p>
                                    {item.ACType &&
                                    item.ACType.length > maxLength
                                      ? `${item.ACType.substring(
                                          0,
                                          maxLength
                                        )}...`
                                      : item.ACType}
                                  </p>
                                </div>
                                <div>
                                  <li>الضمان</li>
                                  <p>{item.WarrantyStatusName}</p>
                                </div>
                                <div>
                                  <li>الملاحظات</li>
                                  <p>
                                    {item.ErrorType &&
                                    item.ErrorType.length > maxLength
                                      ? `${item.ErrorType.substring(
                                          0,
                                          maxLength
                                        )}...`
                                      : item.ErrorType}
                                  </p>
                                </div>
                                <div>
                                  <li>الفاتوره</li>
                                  {item.ImagePath ? (
                                    <img
                                      src={`${item.ImagePath}`}
                                      alt="Receipt"
                                    />
                                  ) : (
                                    <p>No Imsgr</p>
                                  )}
                                </div>
                                <div className="control-box">
                                  <li>التحكم</li>
                                  <div className="btn-holder text-center">
                                    <button
                                      onClick={() =>
                                        handleMoveDataToWaiting(item, 2)
                                      }
                                    >
                                      قبول
                                    </button>
                                    <button
                                      className="m-1"
                                      key={item.RequestId}
                                      onClick={() =>
                                        handleOpenRejectionPopup(item)
                                      }
                                    >
                                      رفض
                                    </button>
                                    {showRejectionPopup &&
                                      selectedRequestId && (
                                        <div className="rejection-popup">
                                          <h4>سبب الرفض</h4>
                                          <div className="rejection-popup-container">
                                            <textarea
                                              value={rejectionReason}
                                              onChange={(e) =>
                                                setRejectionReason(
                                                  e.target.value
                                                )
                                              }
                                              placeholder="أكتب سبب الرفض هنا"
                                              required
                                            />
                                            <div className="popup-actions">
                                              <button
                                                onClick={() =>
                                                  setShowRejectionPopup(false)
                                                }
                                              >
                                                إلغاء
                                              </button>
                                              <button
                                                onClick={() =>
                                                  handleMoveDataToRejected(
                                                    item,
                                                    3
                                                  )
                                                }
                                              >
                                                تأكيد
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    <Link
                                      className="link"
                                      to={`/dashboard/inProgress/${item.RequestId}`}
                                    >
                                      <button>تفاصيل</button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          );
                        })
                      ) : (
                        <p className="text-center">No Data Available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default NewAppointment;
