import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Sidebar from "../sidebar/Sidebar";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import "./inProgress.css";
import MobileNav from "../mobileNav/MobileNav";

const InProgress = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(null);
  const [phoneNom, setPhoneNom] = useState("");
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [counts, setCounts] = useState(Array(0).fill(0));
  const [selectedRequestId, setSelectedRequestId] = useState(null);

  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const [selectedToSend, setSelectedToSend] = useState([]);

  const url = `https://aircon-api.basilalasadi.com/api/maintenance/GetInProgressRequests`;

  const maxLength = 10;
  const maxLengthCity = 6;
  const maxLengthTech = 7;

  const getAllAppointments = async () => {
    try {
      const technicianId = localStorage.getItem("UserId");
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const response = await axios.post(url, {
        maintenanceAreaIds: maintenanceAreaIds,
        technicianId: technicianId,
        MaintenanceStartDate: date,
        PhoneNumber: phoneNom.trim() === "" ? null : phoneNom.trim(),
      });
      setAppointments(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errorMessages = [];
    if (selectedToSend.length === 0) {
      isValid = false;
      setError("يجب اختيار قطعة غيار واحدة على الأقل");
    }
    for (const optionId of selectedToSend) {
      if (counts[optionId] === undefined || counts[optionId] < 0) {
        isValid = false;
        setError(`الكمية غير صحيحة لقطعة الغيار`);
      }
    }
    if (!isValid) {
      console.error("Validation errors:", errorMessages);
      return false;
    }
    return true;
  };

  const moveDataToMaintainceDone = async (appointment, condition) => {
    if (validateForm()) {
      try {
        const selectedParts = selectedToSend.map((Id) => ({
          AirCondMaintPartsId: Id,
          NumberOfMaintParts: counts[Id] || 0,
        }));
        await axios.post(
          "https://aircon-api.basilalasadi.com/api/maintenance/FinishMaintenance",
          {
            RequestId: selectedRequestIdRef.current,
            ProcessingTypeId: condition,
            UserId: localStorage.getItem("UserId"),
            AirCondMaintPartsIds: selectedParts,
          }
        );
        toast.success("تم انهاء الطلب بنجاح", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setAppointments(
          appointmentRef.current.filter(
            (d) => d.RequestId !== appointmentRef.current.RequestId
          )
        );
        setShowPopup(false);
        setSelectedRequestId(null);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const moveDataToCancelled = async (appointment, condition) => {
    try {
      const parsedData = JSON.parse(JSON.stringify(appointment));
      const requestId = parsedData.RequestId;
      Swal.fire({
        title: "هل أنت متأكد من الغاء الطلب؟",
        text: "لن تستطيع استعاده الطلب بعد الالغاء!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, move it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        backdrop: `rgba(0,0,123,0.4)`,
        customClass: {
          container: "my-swal",
        },
      }).then(async (willMove) => {
        if (willMove.value) {
          try {
            await axios.post(
              "https://aircon-api.basilalasadi.com/api/maintenance/CancelRequest",
              {
                RequestId: requestId,
                ProcessingTypeId: condition,
                UserId: localStorage.getItem("UserId"),
              }
            );
            toast.success("تم الغاء الطلب بنجاح", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setAppointments(
              appointments.filter((d) => d.RequestId !== appointment.RequestId)
            );
            getAllAppointments();
          } catch (error) {
            console.error("Error moving request:", error);
          }
        } else if (willMove.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: "Cancelled",
            text: "request didn't move",
            icon: "info",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        }
      });
    } catch (error) {
      console.error("Error parsing data:", error);
    }
  };

  const getAllSpareParts = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        "https://aircon-api.basilalasadi.com/api/AirCondMaintParts"
      );
      setOptions(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  const handleSearch = async (e) => {
    e.preventDefault();
    await getAllAppointments();
  };

  const selectedRequestIdRef = useRef(null);
  const appointmentRef = useRef(null);

  const togglePopup = (appointment) => {
    setSelectedAppointment(appointment);
    appointmentRef.current = appointment;
    setSelectedRequestId(appointment.RequestId);
    selectedRequestIdRef.current = appointment.RequestId;
    setShowPopup(!showPopup);
  };

  const increase = (index) => {
    setCounts((prevCounts) => {
      const updatedCounts = [...prevCounts];
      updatedCounts[index] = (updatedCounts[index] || 0) + 1;
      return updatedCounts;
    });
  };

  const decrease = (index) => {
    setCounts((prevCounts) => {
      const updatedCounts = [...prevCounts];
      updatedCounts[index] = Math.max((updatedCounts[index] || 0) - 1, 0);
      return updatedCounts;
    });
  };

  const handleCheckboxChange = (Id) => {
    const newSelected = [...selectedToSend];
    if (newSelected.includes(Id)) {
      const index = newSelected.indexOf(Id);
      newSelected.splice(index, 1);
    } else {
      // Add to selected
      newSelected.push(Id);
    }
    setSelectedToSend(newSelected);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    getAllAppointments();
    getAllSpareParts();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <MobileNav />
      <section className="newAppointment finished mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>المواعيد التي تم جدولتها</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/inProgress"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <div className="newAppointment-container">
            <div className="row">
              <div className="col-lg-2">
                <Sidebar />
              </div>
              <div
                className="col-lg-10 col-md-12 col-sm-12 col-xs-12 pt-4 col"
                ref={componentRef}
              >
                <div className="d-flex justify-content-between info">
                  <div className="title d-flex">
                    <h2 className="mx-2 mb-4">المواعيد المجدولة</h2>
                    <h3 className="mb-3">{appointments.length}</h3>
                  </div>
                  <div className="buttons">
                    <button className="printButton" onClick={handlePrint}>
                      طباعة التقرير
                    </button>
                  </div>
                </div>
                <div className="form-holder">
                  <h5>ما الذي تبحث عنه؟</h5>
                  <form onSubmit={handleSearch} className="mt-4">
                    <input
                      type="date"
                      onChange={(e) => setDate(e.target.value)}
                      value={date}
                    />
                    <input
                      placeholder="إبحث برقم الهاتف"
                      type="text"
                      onChange={(e) => setPhoneNom(e.target.value)}
                      value={phoneNom}
                    />
                    <button disabled={loading}>
                      {loading ? "جاري البحث..." : "بحث"}
                    </button>
                  </form>
                </div>
                <div className="table-responsive-sm overflow-auto">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="row">رقم الطلب</th>
                        <th scope="row">إسم العميل</th>
                        <th scope="row">رقم الهاتف</th>
                        <th scope="row">المدينه</th>
                        <th scope="row">الحي</th>
                        <th scope="row">نوع المكيف</th>
                        <th scope="row">تاريخ الصيانه</th>
                        <th scope="row">الفني</th>
                        <th scope="row" className="text-center">
                          التحكم
                        </th>
                      </tr>
                    </thead>
                    {appointments?.length > 0 ? (
                      <tbody>
                        {appointments.map((appointment) => (
                          <tr key={appointment.RequestId}>
                            <td>{appointment.FormattedRequestNumber}</td>
                            <td>
                              {appointment.CustomerName &&
                              appointment.CustomerName.length > maxLength
                                ? `${appointment.CustomerName.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : appointment.CustomerName}
                            </td>
                            <td>
                              <a
                                href={`https://api.whatsapp.com/send?phone=+966${appointment.PhoneNumber.replace(
                                  /[^\d+]/g,
                                  ""
                                )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                                target="__blank"
                              >
                                {appointment.PhoneNumber}
                              </a>
                            </td>
                            <td>
                              {appointment.City &&
                              appointment.City.length > maxLengthCity
                                ? `${appointment.City.substring(
                                    0,
                                    maxLengthCity
                                  )}...`
                                : appointment.City}
                            </td>
                            <td>
                              {appointment.Neighborhood &&
                              appointment.Neighborhood.length > maxLengthCity
                                ? `${appointment.Neighborhood.substring(
                                    0,
                                    maxLengthCity
                                  )}...`
                                : appointment.Neighborhood}
                            </td>
                            <td>
                              {appointment.ACType &&
                              appointment.ACType.length > maxLength
                                ? `${appointment.ACType.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : appointment.ACType}
                            </td>
                            <td>{appointment.MaintenanceStartDate}</td>
                            <td>
                              {appointment.TechnicianName &&
                              appointment.TechnicianName.length > maxLengthTech
                                ? `...${appointment.TechnicianName.substring(
                                    0,
                                    maxLengthTech
                                  )}`
                                : appointment.TechnicianName}
                            </td>
                            <td className="btn-box">
                              <div className="firstBtnBox d-flex">
                                <button
                                  onClick={() =>
                                    moveDataToCancelled(appointment, 6)
                                  }
                                >
                                  إلغاء
                                </button>
                                <button
                                  key={appointment.RequestId}
                                  onClick={() => togglePopup(appointment)}
                                >
                                  إنهاء
                                </button>
                              </div>
                              <Link
                                className="w-100"
                                to={`/dashboard/inProgress/${appointment.RequestId}`}
                              >
                                <button className="w-100">تفاصيل</button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="no-data-message">
                          <td colSpan="2">لا توجد بيانات لعرضها حاليا :(</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {showPopup && selectedRequestId && selectedAppointment && (
                <div className="layout">
                  <div className="finished-popup">
                    <div className="popup-content">
                      <div className="headliner d-flex justify-content-between mb-3">
                        <h3>قطع الغيار</h3>
                        <div className="btn-box d-flex g-2 flex-row">
                          <button onClick={() => closePopup()}>خروج</button>
                          <button
                            onClick={() => {
                              moveDataToMaintainceDone(
                                appointmentRef.current,
                                5
                              );
                            }}
                          >
                            إنهاء
                          </button>
                        </div>
                      </div>
                      <div className="table-container overflow-auto">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>اختار</th>
                              <th>قطع الغيار</th>
                              <th>العدد</th>
                            </tr>
                          </thead>
                          <tbody>
                            {options.map((option) => {
                              return (
                                <tr key={option.Id}>
                                  <td>
                                    <input
                                      key={option.Id}
                                      type="checkbox"
                                      checked={selectedToSend.includes(
                                        option.Id
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(option.Id)
                                      }
                                    />
                                  </td>
                                  <td>{option.Name}</td>
                                  <td>
                                    <div className="counter">
                                      <button
                                        onClick={() => decrease(option.Id)}
                                      >
                                        -
                                      </button>
                                      <span>
                                        {counts[option.Id] !== undefined
                                          ? counts[option.Id].toString()
                                          : 0}
                                      </span>
                                      <button
                                        onClick={() => increase(option.Id)}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default InProgress;
