import React from "react";
import { Link } from "react-router-dom";
import "./hero.css";

const Hero = () => {
  return (
    <section className="hero">
      <div className="overlay"></div>
      <div className="hero-container container">
        <div className="row align-items-center justifu-content-center">
          <div className="col-lg-12 col-md-12">
            <div className="text-box">
              <h2>صيانة التكييفات الصحراوية</h2>
              <p>
                نقدم أفضل خدمات الصيانة و التبريد و نسعى إلى ضمان رضا العميل
                بنسبة 100%
              </p>
              <Link to="/VerifiyMobileNumber">
                <button>إحجز الآن</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
