import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useReactToPrint } from "react-to-print";
import "./rejectedList.css";
import MobileNav from "../mobileNav/MobileNav";

const RejectedList = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  const maxLength = 10;

  const getAllAppointments = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const url = `https://aircon-api.basilalasadi.com/api/maintenance/GetRejectedRequests`;
      const response = await axios.post(url, {
        maintenanceAreaIds: maintenanceAreaIds,
      });
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  useEffect(() => {
    getAllAppointments();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <MobileNav />
      <section className="newAppointment rejectedList mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>الطلبات المرفوضه</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/rejectedList"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <div className="newAppointment-container">
            <div className="row">
              <div className="col-lg-2 ">
                <Sidebar data={data} />
              </div>
              <div
                className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col pt-4"
                ref={componentRef}
              >
                <div className="d-flex justify-content-between info">
                  <div className="title d-flex">
                    <h2 className="mx-2 mb-4">طلبات الصيانه المرفوضه:</h2>
                    <h3 className="mb-3">{data.length}</h3>
                  </div>
                  <div className="buttons">
                    <button className="printButton" onClick={handlePrint}>
                      طباعة التقرير
                    </button>
                  </div>
                </div>
                <div className="table-responsive-sm overflow-auto">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="row">الإسم</th>
                        <th scope="row">رقم الهاتف</th>
                        <th scope="row">المنطقه</th>
                        <th scope="row">المدينه</th>
                        <th scope="row">الحي</th>
                        <th scope="row">نوع المكيف</th>
                        <th scope="row">حالة الضمان</th>
                        <th scope="row" className="text-center">
                          التحكم
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        data.map((item) => (
                          <tr key={item.RequestId}>
                            <td>
                              {item.CustomerName &&
                              item.CustomerName.length > maxLength
                                ? `${item.CustomerName.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : item.CustomerName}
                            </td>
                            <td>
                              <a
                                href={`https://api.whatsapp.com/send?phone=+966${item.PhoneNumber.replace(
                                  /[^\d+]/g,
                                  ""
                                )}&text=Hello,%20I'm%20interested%20in%20your%20product`}
                                target="__blank"
                              >
                                {item.PhoneNumber}
                              </a>
                            </td>
                            <td>
                              {item.Region && item.Region.length > maxLength
                                ? `${item.Region.substring(0, maxLength)}...`
                                : item.Region}
                            </td>
                            <td>
                              {item.City && item.City.length > maxLength
                                ? `${item.City.substring(0, maxLength)}...`
                                : item.City}
                            </td>
                            <td>
                              {item.Neighborhood &&
                              item.Neighborhood.length > maxLength
                                ? `${item.Neighborhood.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : item.Neighborhood}
                            </td>
                            <td>
                              {item.ACType && item.ACType.length > maxLength
                                ? `${item.ACType.substring(0, maxLength)}...`
                                : item.ACType}
                            </td>
                            <td>{item.WarrantyStatusName}</td>
                            <td className="text-center btn-holder">
                              <Link
                                to={`/dashboard/inProgress/${item.RequestId}`}
                                className="link m-1"
                              >
                                <button>تفاصيل</button>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="no-data-message">
                          <td colSpan="10">لا توجد بيانات لعرضها حاليا :(</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default RejectedList;
