import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import Appointment from "./pages/appointment/Appointment";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ClipLoader from "react-spinners/ClipLoader";

import "./App.css";
import NewAppointment from "./components/newAppointment/NewAppointment";
import RejectedList from "./components/rejectedList/RejectedList";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import TechnicalScreen from "./pages/technicalScreen/TechnicalScreen";

import ErrorInterceptor from "./interceptores/ErrorInterceptor";
import TokenInterceptor from "./interceptores/TokenInterceptor";

import MaintainceDone from "./components/maintainceDone/MaintainceDone";
import ApprovedList from "./components/waitingList/ApprovedList";
import InProgress from "./components/finishedList/InProgress";
import Reportes from "./pages/reportes/Reportes";
import AddAcTypes from "./components/addAcTypes/AddAcTypes";
import RequestDetails from "./components/requestDetails/RequestDetails";
import ScheduledProjects from "./pages/scheduledProjects/ScheduledProjects";
import DoneProjects from "./pages/doneProjects/DoneProjects";
import UpdateActypes from "./components/updateActypes/UpdateActypes";
import AddSpareParts from "./components/addSpareParts/AddSpareParts";
import UpdateSpareParts from "./components/updateSpareParts/UpdateSpareParts";
import ChartsReport from "./pages/chartsReport/ChartsReport";
import EidMubarak from "./pages/eidMubarak/EidMubarak";
import CancelledRequests from "./pages/cancelledRequests/CancelledRequests";
import VerifiyMobileNumber from "./pages/nomVerification/VerifiyMobileNumber";
import OtpPage from "./pages/OTPPage/OtpPage";
import ManagerAppointment from "./pages/managerAppointment/ManagerAppointment";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="appointment" element={<Appointment />} />
        <Route path="eidMubarak" element={<EidMubarak />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="VerifiyMobileNumber" element={<VerifiyMobileNumber />} />
        <Route path="otpPage" element={<OtpPage />} />
      </Route>
      <Route path="/dashboard" element={<Dashboard />}>
        <Route path="managerAppointment" element={<ManagerAppointment />} />
        <Route path="newAppointment" element={<NewAppointment />} />
        <Route path="approvesList" element={<ApprovedList />} />
        <Route path="inProgress" element={<InProgress />} />
        <Route path="inProgress/:inProgressId" element={<RequestDetails />} />
        <Route path="rejectedList" element={<RejectedList />} />
        <Route path="maintainceDone" element={<MaintainceDone />} />
        <Route path="cancelledRequests" element={<CancelledRequests />} />
        <Route path="technicalScreen" element={<TechnicalScreen />} />
        <Route path="addAcTypes" element={<AddAcTypes />} />
        <Route path="addAcTypes/:updateId" element={<UpdateActypes />} />
        <Route path="addSpareParts" element={<AddSpareParts />} />
        <Route path="addSpareParts/:updateId" element={<UpdateSpareParts />} />
        <Route path="reportes" element={<Reportes />} />
        <Route path="chartsReport" element={<ChartsReport />} />
        <Route
          path="scheduledProjects/:scheduledProjectsId"
          element={<ScheduledProjects />}
        />
        <Route path="doneProjects/:doneProjectsId" element={<DoneProjects />} />
      </Route>
    </>
  )
);

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <div className="app">
      {loading ? (
        <div className="spinner-container">
          <ClipLoader
            className="loader"
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="#00b1ff"
          />
        </div>
      ) : (
        <>
          <ToastContainer />
          <RouterProvider router={routes} />
        </>
      )}
    </div>
  );
}

export default App;
