import React, { useRef } from "react";
import MaintainceState from "../../components/charts/maintainceState/MaintainceState";
import WarrantyChart from "../../components/charts/warrantyChart/WarrantyChart";
import TechnicianChart from "../../components/charts/technicianChart/TechnicianChart";
import AcTypesChart from "../../components/charts/acTypesChart/AcTypesChart";
import Sidebar from "../../components/sidebar/Sidebar";
import { useReactToPrint } from "react-to-print";
import "./chartsReport.css";
import RegionChart from "../../components/charts/regionChart/RegionChart";
import MobileNav from "../../components/mobileNav/MobileNav";

const ChartsReport = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });
  return (
    <>
      <MobileNav />
      <div className="chartsReport">
        <div className="chartsReport-container">
          <div className="row">
            <div className="col-lg-2 sidebarCol">
              <Sidebar />
            </div>
            <div className="col-lg-10 mainCol" ref={componentRef}>
              <div className="row">
                <div className="col-lg-12 col">
                  <div className="chartsHeder d-flex justify-content-between mx-5">
                    <h2>الإحصائات</h2>
                    <button className="printButton" onClick={handlePrint}>
                      طباعة التقرير
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 mb-4 h-75 p-0">
                  <WarrantyChart />
                </div>
                <div className="col-lg-6 mb-4 h-100 p-0">
                  <MaintainceState />
                </div>
                <div className="col-lg-6 mb-4 h-100 p-0">
                  <RegionChart />
                  <TechnicianChart />
                </div>
                <div className="col-lg-6 mb-4 h-100 p-0">
                  <AcTypesChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartsReport;
