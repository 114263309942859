import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from "../../assets/Opensource-02.jpg";
import IconAC from "../../assets/icons/Air Conditioner.png";
import "./sidebar.css";

const Sidebar = () => {
  const [pendingLength, setPendingLength] = useState(0);
  const navigate = useNavigate();

  const getPendingLength = async () => {
    const currentStatusId = 1;
    try {
      const urlPending = `https://aircon-api.basilalasadi.com/api/maintenance/GetPenddingRequestsCount/${currentStatusId}`;
      const response = await axios.get(urlPending);
      setPendingLength(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    getPendingLength();
  }, []);

  return (
    <>
      <div className="sidebar">
        <Link className="logo-box" to={"/"}>
          <img src={Logo} alt="Logo" />
        </Link>
        <div className="fastArrival">
          <h4>الوصول السريع</h4>
          <ul>
            <li>
              <Link to="/dashboard/managerAppointment" className="active">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
                    fill="white"
                  />
                  <path
                    d="M18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C5.91 19.02 8.67 16.97 12 16.97C15.33 16.97 18.09 19.02 18.44 21.66Z"
                    fill="white"
                  />
                  <path
                    d="M15.58 11.58C15.58 13.56 13.98 15.17 12 15.17C10.02 15.17 8.41998 13.56 8.41998 11.58C8.41998 9.60002 10.02 8 12 8C13.98 8 15.58 9.60002 15.58 11.58Z"
                    fill="white"
                  />
                </svg>
                إضافة طلب جديد
              </Link>
            </li>
            <li>
              <Link to="/dashboard/technicalScreen" className="active">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
                    fill="white"
                  />
                  <path
                    d="M18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C5.91 19.02 8.67 16.97 12 16.97C15.33 16.97 18.09 19.02 18.44 21.66Z"
                    fill="white"
                  />
                  <path
                    d="M15.58 11.58C15.58 13.56 13.98 15.17 12 15.17C10.02 15.17 8.41998 13.56 8.41998 11.58C8.41998 9.60002 10.02 8 12 8C13.98 8 15.58 9.60002 15.58 11.58Z"
                    fill="white"
                  />
                </svg>
                بيانات الموظفين
              </Link>
            </li>
            <li>
              <Link to="/Register">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
                    fill="white"
                  />
                  <path
                    d="M18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C5.91 19.02 8.67 16.97 12 16.97C15.33 16.97 18.09 19.02 18.44 21.66Z"
                    fill="white"
                  />
                  <path
                    d="M15.58 11.58C15.58 13.56 13.98 15.17 12 15.17C10.02 15.17 8.41998 13.56 8.41998 11.58C8.41998 9.60002 10.02 8 12 8C13.98 8 15.58 9.60002 15.58 11.58Z"
                    fill="white"
                  />
                </svg>
                إضافة فني
              </Link>
            </li>
            <li>
              <Link to="/dashboard/addAcTypes" className="d-flex">
                <div className="icon-box">
                  <img src={IconAC} alt="IconAC" />
                </div>
                إضافة نوع المكيف
              </Link>
            </li>
            <li>
              <Link to="/dashboard/addSpareParts">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z"
                    fill="white"
                  />
                  <path
                    d="M18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C5.91 19.02 8.67 16.97 12 16.97C15.33 16.97 18.09 19.02 18.44 21.66Z"
                    fill="white"
                  />
                  <path
                    d="M15.58 11.58C15.58 13.56 13.98 15.17 12 15.17C10.02 15.17 8.41998 13.56 8.41998 11.58C8.41998 9.60002 10.02 8 12 8C13.98 8 15.58 9.60002 15.58 11.58Z"
                    fill="white"
                  />
                </svg>
                إضافة قطع الغيار
              </Link>
            </li>
          </ul>
        </div>
        <div className="requests">
          <h4>الطلبات</h4>
          <ul>
            <li>
              <Link to="/dashboard/newAppointment" className="newAppointment">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M19.3399 14.4899L18.3399 12.8299C18.1299 12.4599 17.9399 11.7599 17.9399 11.3499V8.8199C17.9399 5.5599 15.2899 2.8999 12.0199 2.8999C8.74994 2.8999 6.09994 5.5599 6.09994 8.8199V11.3499C6.09994 11.7599 5.90994 12.4599 5.69994 12.8199L4.68994 14.4899C4.28994 15.1599 4.19994 15.8999 4.44994 16.5799C4.68994 17.2499 5.25994 17.7699 5.99994 18.0199C7.93994 18.6799 9.97994 18.9999 12.0199 18.9999C14.0599 18.9999 16.0999 18.6799 18.0399 18.0299C18.7399 17.7999 19.2799 17.2699 19.5399 16.5799C19.7999 15.8899 19.7299 15.1299 19.3399 14.4899Z"
                    fill="white"
                  />
                  <path
                    d="M14.25 3.32C13.56 3.05 12.81 2.9 12.02 2.9C11.24 2.9 10.49 3.04 9.80005 3.32C10.23 2.51 11.08 2 12.02 2C12.97 2 13.81 2.51 14.25 3.32Z"
                    fill="white"
                  />
                  <path
                    d="M14.8299 20.01C14.4099 21.17 13.2999 22 11.9999 22C11.2099 22 10.4299 21.68 9.87993 21.11C9.55993 20.81 9.31993 20.41 9.17993 20C9.30993 20.02 9.43993 20.03 9.57993 20.05C9.80993 20.08 10.0499 20.11 10.2899 20.13C10.8599 20.18 11.4399 20.21 12.0199 20.21C12.5899 20.21 13.1599 20.18 13.7199 20.13C13.9299 20.11 14.1399 20.1 14.3399 20.07C14.4999 20.05 14.6599 20.03 14.8299 20.01Z"
                    fill="white"
                  />
                </svg>
                الطلبات الجديدة
                <span className="p-1">
                  {pendingLength?.Count !== 0 ? pendingLength.Count : 0}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/dashboard/approvesList">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M21.99 6.01996C22.01 6.25996 21.99 6.50995 21.93 6.75995L18.56 20.2899C18.32 21.2999 17.42 21.9999 16.38 21.9999H3.23998C1.72998 21.9999 0.66 20.5199 1.1 19.0699L5.30999 5.53992C5.59999 4.59992 6.47001 3.95996 7.45001 3.95996H19.75C20.71 3.95996 21.49 4.52996 21.82 5.32996C21.92 5.53996 21.97 5.77996 21.99 6.01996Z"
                    fill="white"
                  />
                  <path
                    d="M22.9899 19.62C23.0899 20.91 22.0699 22 20.7799 22H16.3799C17.4199 22 18.3199 21.3 18.5599 20.29L21.9299 6.76001C21.9899 6.51001 22.0099 6.26002 21.9899 6.02002L21.9999 6L22.9899 19.62Z"
                    fill="white"
                  />
                  <path
                    d="M9.68001 7.12989C9.62001 7.12989 9.56002 7.11987 9.50002 7.10987C9.10002 7.00987 8.85003 6.6099 8.95003 6.1999L9.99001 1.87989C10.09 1.47989 10.49 1.2399 10.9 1.3299C11.3 1.4299 11.55 1.82987 11.45 2.23987L10.41 6.55988C10.33 6.89988 10.02 7.12989 9.68001 7.12989Z"
                    fill="white"
                  />
                  <path
                    d="M16.3799 7.14005C16.3299 7.14005 16.2699 7.14003 16.2199 7.12003C15.8199 7.03003 15.56 6.63001 15.64 6.23001L16.5799 1.89005C16.6699 1.48005 17.0699 1.23003 17.4699 1.31003C17.8699 1.40003 18.1299 1.80004 18.0499 2.20004L17.1099 6.54001C17.0399 6.90001 16.7299 7.14005 16.3799 7.14005Z"
                    fill="white"
                  />
                  <path
                    d="M15.7 12.75H7.69995C7.28995 12.75 6.94995 12.41 6.94995 12C6.94995 11.59 7.28995 11.25 7.69995 11.25H15.7C16.11 11.25 16.45 11.59 16.45 12C16.45 12.41 16.11 12.75 15.7 12.75Z"
                    fill="white"
                  />
                  <path
                    d="M14.7 16.75H6.69995C6.28995 16.75 5.94995 16.41 5.94995 16C5.94995 15.59 6.28995 15.25 6.69995 15.25H14.7C15.11 15.25 15.45 15.59 15.45 16C15.45 16.41 15.11 16.75 14.7 16.75Z"
                    fill="white"
                  />
                </svg>
                قائمة الإنتظار
              </Link>
            </li>
            <li>
              <Link to="/dashboard/inProgress">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    fill="white"
                  />
                  <path
                    d="M15.7099 15.93C15.5799 15.93 15.4499 15.9 15.3299 15.82L12.2299 13.97C11.4599 13.51 10.8899 12.5 10.8899 11.61V7.51001C10.8899 7.10001 11.2299 6.76001 11.6399 6.76001C12.0499 6.76001 12.3899 7.10001 12.3899 7.51001V11.61C12.3899 11.97 12.6899 12.5 12.9999 12.68L16.0999 14.53C16.4599 14.74 16.5699 15.2 16.3599 15.56C16.2099 15.8 15.9599 15.93 15.7099 15.93Z"
                    fill="white"
                  />
                </svg>
                المواعيد المجدولة
              </Link>
            </li>
            <li>
              <Link to="/dashboard/rejectedList">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
                    fill="white"
                  />
                  <path
                    d="M13.0601 12.0001L15.3601 9.70011C15.6501 9.41011 15.6501 8.93011 15.3601 8.64011C15.0701 8.35011 14.5901 8.35011 14.3001 8.64011L12.0001 10.9401L9.70011 8.64011C9.41011 8.35011 8.93011 8.35011 8.64011 8.64011C8.35011 8.93011 8.35011 9.41011 8.64011 9.70011L10.9401 12.0001L8.64011 14.3001C8.35011 14.5901 8.35011 15.0701 8.64011 15.3601C8.79011 15.5101 8.98011 15.5801 9.17011 15.5801C9.36011 15.5801 9.55011 15.5101 9.70011 15.3601L12.0001 13.0601L14.3001 15.3601C14.4501 15.5101 14.6401 15.5801 14.8301 15.5801C15.0201 15.5801 15.2101 15.5101 15.3601 15.3601C15.6501 15.0701 15.6501 14.5901 15.3601 14.3001L13.0601 12.0001Z"
                    fill="white"
                  />
                </svg>
                الطلبات المرفوضة
              </Link>
            </li>
            <li>
              <Link to="/dashboard/cancelledRequests">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
                    fill="white"
                  />
                  <path
                    d="M13.0601 12.0001L15.3601 9.70011C15.6501 9.41011 15.6501 8.93011 15.3601 8.64011C15.0701 8.35011 14.5901 8.35011 14.3001 8.64011L12.0001 10.9401L9.70011 8.64011C9.41011 8.35011 8.93011 8.35011 8.64011 8.64011C8.35011 8.93011 8.35011 9.41011 8.64011 9.70011L10.9401 12.0001L8.64011 14.3001C8.35011 14.5901 8.35011 15.0701 8.64011 15.3601C8.79011 15.5101 8.98011 15.5801 9.17011 15.5801C9.36011 15.5801 9.55011 15.5101 9.70011 15.3601L12.0001 13.0601L14.3001 15.3601C14.4501 15.5101 14.6401 15.5801 14.8301 15.5801C15.0201 15.5801 15.2101 15.5101 15.3601 15.3601C15.6501 15.0701 15.6501 14.5901 15.3601 14.3001L13.0601 12.0001Z"
                    fill="white"
                  />
                </svg>
                الطلبات الملغيه
              </Link>
            </li>
            <li>
              <Link to="/dashboard/maintainceDone">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
                    fill="white"
                  />
                  <path
                    d="M10.5799 15.58C10.3799 15.58 10.1899 15.5 10.0499 15.36L7.21994 12.53C6.92994 12.24 6.92994 11.76 7.21994 11.47C7.50994 11.18 7.98994 11.18 8.27994 11.47L10.5799 13.77L15.7199 8.63C16.0099 8.34 16.4899 8.34 16.7799 8.63C17.0699 8.92 17.0699 9.4 16.7799 9.69L11.1099 15.36C10.9699 15.5 10.7799 15.58 10.5799 15.58Z"
                    fill="white"
                  />
                </svg>
                الطلبات المكتملة
              </Link>
            </li>
          </ul>
        </div>
        <div className="reports">
          <h4>التقارير</h4>
          <ul>
            <li>
              <Link to="/dashboard/chartsReport">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.19 2H7.82001C4.18001 2 2.01001 4.17 2.01001 7.81V16.18C2.01001 19.82 4.18001 21.99 7.82001 21.99H16.19C19.83 21.99 22 19.82 22 16.18V7.81C22 4.17 19.83 2 16.19 2Z"
                    fill="white"
                  />
                  <path
                    d="M10.11 11.15H7.46005C6.83005 11.15 6.32007 11.66 6.32007 12.29V17.41H10.11V11.15Z"
                    fill="white"
                  />
                  <path
                    opacity="0.4"
                    d="M12.7601 6.59998H11.2401C10.6101 6.59998 10.1001 7.10999 10.1001 7.73999V17.4H13.8901V7.73999C13.8901 7.10999 13.3901 6.59998 12.7601 6.59998Z"
                    fill="white"
                  />
                  <path
                    d="M16.55 12.85H13.9V17.4H17.69V13.99C17.68 13.36 17.17 12.85 16.55 12.85Z"
                    fill="white"
                  />
                </svg>
                الإحصائيات
              </Link>
            </li>
            <li>
              <Link to="/dashboard/reportes">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M21.6599 10.44L20.6799 14.62C19.8399 18.23 18.1799 19.69 15.0599 19.39C14.5599 19.35 14.0199 19.26 13.4399 19.12L11.7599 18.72C7.58994 17.73 6.29994 15.67 7.27994 11.49L8.25994 7.30001C8.45994 6.45001 8.69994 5.71001 8.99994 5.10001C10.1699 2.68001 12.1599 2.03001 15.4999 2.82001L17.1699 3.21001C21.3599 4.19001 22.6399 6.26001 21.6599 10.44Z"
                    fill="white"
                  />
                  <path
                    d="M15.0601 19.3901C14.4401 19.8101 13.6601 20.1601 12.7101 20.4701L11.1301 20.9901C7.1601 22.2701 5.0701 21.2001 3.7801 17.2301L2.5001 13.2801C1.2201 9.3101 2.2801 7.2101 6.2501 5.9301L7.8301 5.4101C8.2401 5.2801 8.6301 5.1701 9.0001 5.1001C8.7001 5.7101 8.4601 6.4501 8.2601 7.3001L7.2801 11.4901C6.3001 15.6701 7.5901 17.7301 11.7601 18.7201L13.4401 19.1201C14.0201 19.2601 14.5601 19.3501 15.0601 19.3901Z"
                    fill="white"
                  />
                  <path
                    d="M17.4901 10.51C17.4301 10.51 17.3701 10.5 17.3001 10.49L12.4501 9.26002C12.0501 9.16002 11.8101 8.75002 11.9101 8.35002C12.0101 7.95002 12.4201 7.71002 12.8201 7.81002L17.6701 9.04002C18.0701 9.14002 18.3101 9.55002 18.2101 9.95002C18.1301 10.28 17.8201 10.51 17.4901 10.51Z"
                    fill="white"
                  />
                  <path
                    d="M14.5599 13.8899C14.4999 13.8899 14.4399 13.8799 14.3699 13.8699L11.4599 13.1299C11.0599 13.0299 10.8199 12.6199 10.9199 12.2199C11.0199 11.8199 11.4299 11.5799 11.8299 11.6799L14.7399 12.4199C15.1399 12.5199 15.3799 12.9299 15.2799 13.3299C15.1999 13.6699 14.8999 13.8899 14.5599 13.8899Z"
                    fill="white"
                  />
                </svg>
                التقارير
              </Link>
            </li>
          </ul>
        </div>
        <div className="logOut">
          <Link className="button" to="/login" onClick={logout}>
            <button>تسجيل الخروج</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
