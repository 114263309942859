import React, { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "./reportes.css";
import MobileNav from "../../components/mobileNav/MobileNav";

const Reportes = (props) => {
  const { pendingLength } = props;

  const [totalItems, setTotalItems] = useState(0);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [techSearch, setTechSearch] = useState("");
  const [phoneNomSearch, setPhoneNomSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [warranty, setWarranty] = useState(null);
  const [resetSearch, setResetSearch] = useState(false);
  const [acType, setAcType] = useState("");
  const [data, setData] = useState([]);

  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [selectedForDelete, setSelectedForDelete] = useState([]);

  let limit = 10;
  const maxLength = 10;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  const getPagination = async (currentPage = 1) => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const url = `https://aircon-api.basilalasadi.com/api/maintenance/AllRequestsReportPaging`;
      const res = await axios.post(url, {
        maintenanceAreaIds,
        pageSize: limit,
        pageNumber: currentPage,
        TechnicianName: techSearch.toLowerCase().trim(),
        PhoneNumber: phoneNomSearch.toLowerCase().trim(),
        WarrantyStatusId: warranty === "" ? null : warranty,
        ACTypeId: acType === "" ? null : acType,
        City: citySearch === "" ? null : citySearch.trim(),
      });
      const totalItems = res.data.totalItems;
      const totalPages = res.data.totalPages || 1;
      const totalNumberOfPages = totalPages || Math.ceil(totalItems / limit);
      setPageCount(totalNumberOfPages);
      setItems(res.data.data);
      setTotalItems(res.data.totalItems);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getAllAcTypes = async () => {
    try {
      const url = `https://aircon-api.basilalasadi.com/api/ACTypes`;
      const response = await axios.get(url);
      setData(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    await getPagination();
  };

  const handleRemoveSearch = (e) => {
    setTechSearch("");
    setPhoneNomSearch("");
    setWarranty("");
    setAcType("");
    setCitySearch("");
    setResetSearch(true);
    e.preventDefault();
  };

  const handlePageClick = async (data) => {
    const currentPage = data.selected + 1;
    await getPagination(currentPage);
  };

  const handleCheckboxChange = (RequestId) => {
    const newSelected = [...selectedForDelete];
    if (newSelected.includes(RequestId)) {
      // Remove from selected
      const index = newSelected.indexOf(RequestId);
      newSelected.splice(index, 1);
    } else {
      // Add to selected
      newSelected.push(RequestId);
    }
    setSelectedForDelete(newSelected);
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "هل أنت متأكد",
      text: "لن تستطيع استعاده الطلب !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      backdrop: `rgba(0,0,123,0.4)`,
      customClass: {
        container: "my-swal",
      },
    }).then(async (willDelete) => {
      if (willDelete.value) {
        if (selectedForDelete.length === 0) {
          return;
        }
        try {
          await axios.delete(
            `https://aircon-api.basilalasadi.com/api/maintenance/DeleteMultipleMaintenanceRequest/${selectedForDelete.join(
              ","
            )}`
          );
          Swal.fire({
            title: "Deleted!",
            text: "The AC type has been deleted successfully",
            icon: "success",
            confirmButtonText: "Cool",
            timer: 2000,
          });
          getPagination();
        } catch (error) {
          let errorMessage;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
            console.log(errorMessage);
          } else {
            errorMessage = "An unexpected error occurred";
            console.log(errorMessage);
          }
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "Try Again",
          });
          setError(error.response?.data || {});
        }
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your AC type is safe :)",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    });
    setSelectedForDelete([]);
  };

  useEffect(() => {
    getPagination();
    if (resetSearch) {
      getPagination();
      setResetSearch(false);
    }
    getAllAcTypes();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [resetSearch]);

  return (
    <>
      <MobileNav />
      <section className="reportes mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>التقارير</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/reportes"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <div className="reportes-container ">
            <div className="row">
              <div className="col-lg-2">
                <Sidebar pendingLength={pendingLength} />
              </div>
              <div
                ref={componentRef}
                style={{
                  width: "100%",
                }}
                className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col pt-5"
              >
                <div className="buttons d-flex justify-content-between">
                  <div className="d-flex">
                    <h2>التقارير</h2>
                    <h3>{totalItems}</h3>
                  </div>
                  <div className="d-flex">
                    <button
                      className="mx-4 h-100"
                      hidden={selectedForDelete.length === 0}
                      onClick={handleDelete}
                    >
                      مسح
                    </button>
                    <button
                      className="printButton w-100 h-100"
                      onClick={handlePrint}
                    >
                      طباعة التقرير
                    </button>
                  </div>
                </div>
                <div className="form-holder">
                  <h5>ما الذي تبحث عنه؟</h5>
                  <form onSubmit={handleSearch} className="form mt-4">
                    <div>
                      <input
                        type="text"
                        placeholder="إبحث عن رقم الموبايل  ..."
                        onChange={(e) => setPhoneNomSearch(e.target.value)}
                        value={phoneNomSearch}
                      />
                      <input
                        type="text"
                        placeholder="إبحث عن المدينه  ..."
                        onChange={(e) => setCitySearch(e.target.value)}
                        value={citySearch}
                      />
                    </div>
                    <div>
                      <div className="selection-box mb-3">
                        <select
                          className="custom-select w-100"
                          id="inputGroupSelect01"
                          value={warranty}
                          onChange={(e) => setWarranty(e.target.value)}
                        >
                          <option value={""}>عرض الجميع</option>
                          <option value={1}>داخل الضمان</option>
                          <option value={2}>خارج الضمان</option>
                        </select>
                      </div>
                      <div className="selections-box w-50">
                        <div className="selection-box ">
                          <select
                            className="custom-select w-100"
                            onChange={(e) => setAcType(e.target.value)}
                            value={acType}
                          >
                            <option value={""}>عرض الجميع</option>
                            {data.map((item) => {
                              return (
                                <Fragment key={item.ACTypeId}>
                                  <option value={item.ACTypeId}>
                                    {item.Name}
                                  </option>
                                </Fragment>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <button disabled={loading} type="submit">
                      {loading ? "جاري البحث..." : "بحث"}
                    </button>
                  </form>
                </div>
                <div className="row">
                  <div className="paginateComponent col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div>
                      <button type="button" onClick={handleRemoveSearch}>
                        مسح نتيجه البحث
                      </button>
                    </div>
                    <ReactPaginate
                      previousLabel={
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.94 4L6 4.94L9.05333 8L6 11.06L6.94 12L10.94 8L6.94 4Z"
                            fill="black"
                          />
                        </svg>
                      }
                      nextLabel={
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.06 12L11 11.06L7.94667 8L11 4.94L10.06 4L6.06 8L10.06 12Z"
                            fill="black"
                            fillOpacity="0.3"
                          />
                        </svg>
                      }
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
                <div className="table-responsive-sm overflow-auto">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th></th>
                        <th scope="row">رقم الطلب</th>
                        <th scope="row">إسم العميل</th>
                        <th scope="row">رقم الهاتف</th>
                        <th scope="row">المدينه</th>
                        <th scope="row">المكيف</th>
                        <th scope="row">تاريخ الصيانه</th>
                        <th scope="row">الفني</th>
                        <th scope="row">الحاله</th>
                        <th scope="row" className="text-center">
                          التحكم
                        </th>
                      </tr>
                    </thead>
                    {items?.length > 0 ? (
                      <tbody>
                        {items.map((item) => (
                          <tr key={item.RequestId}>
                            <td>
                              <input
                                className="custom-checkbox w-50"
                                type="checkbox"
                                checked={selectedForDelete.includes(
                                  item.RequestId
                                )}
                                onChange={() =>
                                  handleCheckboxChange(item.RequestId)
                                }
                              />
                            </td>
                            <td>{item.FormattedRequestNumber}</td>
                            <td>
                              {item.CustomerName &&
                              item.CustomerName.length > maxLength
                                ? `${item.CustomerName.substring(
                                    0,
                                    maxLength
                                  )}....`
                                : item.CustomerName}
                            </td>
                            <td>
                              <a
                                href={`https://api.whatsapp.com/send?phone=+966${item.PhoneNumber.replace(
                                  /[^\d+]/g,
                                  ""
                                )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                                target="__blank"
                              >
                                {item.PhoneNumber}
                              </a>
                            </td>
                            <td>{item.City}</td>
                            <td>
                              {item.ACType && item.ACType.length > maxLength
                                ? `${item.ACType.substring(0, maxLength)}....`
                                : item.ACType}
                            </td>
                            <td>{item.MaintenanceStartDate}</td>
                            <td>{item.TechnicianName}</td>
                            <td>
                              {item.CurrentStatus === "طلبات جديده" ? (
                                <p className="newReq">جديد</p>
                              ) : item.CurrentStatus ===
                                "الطلبات التي تم الموافقه عليها" ? (
                                <p className="approvedReq">معلق</p>
                              ) : item.CurrentStatus === "الطلبات المرفوضه" ? (
                                <p className="rejectedReq">مرفوض</p>
                              ) : item.CurrentStatus ===
                                "المواعيد التي تم جدولتها" ? (
                                <p className="inProgress">مجدول</p>
                              ) : item.CurrentStatus ===
                                "المواعيد التي تم إلغائها" ? (
                                <p className="rejectedReq">ملغي</p>
                              ) : (
                                <p className="maintainceDone">مكتمل</p>
                              )}
                            </td>
                            <td className="text-center">
                              <Link
                                className="link"
                                to={`/dashboard/inProgress/${item.RequestId}`}
                              >
                                <button>تفاصيل</button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="no-data-message">
                          <td colSpan="2">لا توجد بيانات لعرضها حاليا :(</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Reportes;
