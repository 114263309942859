import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Sidebar from "../sidebar/Sidebar";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useReactToPrint } from "react-to-print";
import "./maintainceDone.css";
import MobileNav from "../mobileNav/MobileNav";

const MaintainceDone = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [citySearch, setCitySearch] = useState("");

  const maxLength = 10;

  const getAllAppointments = async () => {
    try {
      const technicianId = localStorage.getItem("UserId");
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const url = `https://aircon-api.basilalasadi.com/api/maintenance/GetFinishedRequests`;
      const response = await axios.post(url, {
        maintenanceAreaIds: maintenanceAreaIds,
        technicianId: technicianId,
        City: citySearch === "" ? null : citySearch.trim(),
      });
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    await getAllAppointments();
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  useEffect(() => {
    getAllAppointments();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <>
      <MobileNav />
      <section className="newAppointment maintainceDone mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>الطلبات التي تم تنفيذها</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/maintainceDone"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <div className="newAppointment-container">
            <div className="row">
              <div className="col-lg-2">
                <Sidebar />
              </div>
              <div
                className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col pt-4"
                ref={componentRef}
              >
                <div className="d-flex info justify-content-between">
                  <div className="title d-flex">
                    <h2 className="mx-2 mb-4">الطلبات التي تم تنفيذها :</h2>
                    <h3 className="mb-3">{data.length}</h3>
                  </div>
                  <div className="buttons">
                    <button className="printButton" onClick={handlePrint}>
                      طباعة التقرير
                    </button>
                  </div>
                </div>
                <div className="form-holder">
                  <h5>ما الذي تبحث عنه؟</h5>
                  <form onSubmit={handleSearch} className="mt-4">
                    <input
                      type="text"
                      placeholder="ابحث عن المدينه  ..."
                      onChange={(e) => setCitySearch(e.target.value)}
                      value={citySearch}
                    />
                    <button disabled={loading} type="submit">
                      {loading ? "جاري البحث..." : "بحث"}
                    </button>
                  </form>
                </div>
                <div className="table-responsive-sm overflow-auto">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {/* <th scope="row"></th> */}
                        <th scope="row">رقم الطلب</th>
                        <th scope="row" className="text-center">
                          إسم العميل
                        </th>
                        <th scope="row">رقم الهاتف</th>
                        <th scope="row">المدينه</th>
                        <th scope="row">نوع المكيف</th>
                        <th scope="row">تاريخ الصيانه</th>
                        <th scope="row">الفني المسؤول</th>
                        <th scope="row" className="text-center">
                          التحكم
                        </th>
                      </tr>
                    </thead>
                    {data?.length > 0 ? (
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.RequestId}>
                            <td>{item.FormattedRequestNumber}</td>
                            <td>
                              {item.CustomerName &&
                              item.CustomerName.length > maxLength
                                ? `${item.CustomerName.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : item.CustomerName}
                            </td>
                            <td>
                              <a
                                href={`https://api.whatsapp.com/send?phone=+966${item.PhoneNumber.replace(
                                  /[^\d+]/g,
                                  ""
                                )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                                target="__blank"
                              >
                                {item.PhoneNumber}
                              </a>
                            </td>
                            <td className="city">{item.City}</td>
                            <td>
                              {item.ACType && item.ACType.length > maxLength
                                ? `${item.ACType.substring(0, maxLength)}...`
                                : item.ACType}
                            </td>
                            <td>{item.MaintenanceStartDate}</td>
                            <td>{item.TechnicianName}</td>
                            <td className="btn-box">
                              <Link
                                to={`/dashboard/inProgress/${item.RequestId}`}
                              >
                                <button>تفاصيل</button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="no-data-message">
                          <td colSpan="2">لا توجد بيانات لعرضها حاليا :(</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default MaintainceDone;
