import React, { useEffect, useState } from "react";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import datalabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";

ChartJs.register(ArcElement, Tooltip, Legend, LinearScale);
ChartJs.register(datalabels);

const RegionChart = () => {
  const [chart, setChart] = useState([]);

  var baseUrl =
    "https://aircon-api.basilalasadi.com/api/Reports/GetMaintenanceAreaRequests";

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const maintenanceAreaIdsString =
          localStorage.getItem("MaintenanceAreaIds");
        const maintainceAreaId = maintenanceAreaIdsString
          .split(",")
          .map(Number);
        const response = await axios.post(
          baseUrl,
          {
            maintenanceAreaIds: maintainceAreaId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        setChart(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchInfo();
  }, [baseUrl]);

  var data = {
    // labels: chart?.map((x) => x.MaintenanceAreaName),
    datasets: [
      {
        label: ` منطقه الصيانه`,
        data: chart?.map((x) => x.RequestCount),
        backgroundColor: [
          "#8BD7D7",
          "#FFAA57",
          "rgba(54, 162, 235)",
          "rgba(153, 102, 255)",
          "rgba(255, 99, 132)",
          "rgba(255, 159, 64)",
          "rgba(255, 205, 86)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      y: {
        grid: {
          display: false, // Hide horizontal grid lines
        },
        ticks: {
          // Hide Y axis labels (optional)
          display: false,
        },

        beginAtZero: true,
        title: {
          display: false,
          text: "حالة الضمان",
        },
      },
      x: {
        grid: {
          display: false, // Hide horizontal grid lines
        },
        ticks: {
          // Hide Y axis labels (optional)
          display: false,
        },

        lineWidth: 0, // Make Y axis line faint
        color: "#f0f0f0",
      },
    },
    legend: {
      labels: {
        fontSize: 26,
      },
    },
    plugins: {
      datalabels: {
        align: "start",
        anchor: "center",
        color: "black",
        font: {
          // weight: "bold",
          size: 14,
        },
      },
    },
    width: 800,
    height: 400,
  };

  return (
    <div className="warrantyChart">
      <div className="warrantyChart-container container d-flex justify-content-between align-items-center">
        <div className="info-box">
          <h3>أعمال الصيانه</h3>
          <div className="box">
            <h6 className="empty"></h6>
            <h6>أعمال الصيانه بالرياض</h6>
          </div>
          <div className="box">
            <h6 className="emptyI"></h6>
            <h6>أعمال الصيانه بالقصيم</h6>
          </div>
        </div>
        <div>
          <Doughnut data={data} height={400} options={options} />
        </div>
      </div>
    </div>
  );
};

export default RegionChart;
