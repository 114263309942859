import React, { useState } from "react";
import axios from "axios";
import { FaMobileAlt, FaRegUser } from "react-icons/fa";
import "./nomVerification.css";
import OtpPage from "../OTPPage/OtpPage";

function splitMobileNumber(mobileNom) {
  if (mobileNom.startsWith("0")) {
    return mobileNom.substring(1);
  }
  return mobileNom;
}

const isValidMobileNom = (mobileNom) => {
  mobileNom = mobileNom.trim();
  if (mobileNom === "") {
    return false;
  }
  const ksaMobileRegex = /^[0-5]{7}$/;
  if (ksaMobileRegex.test(mobileNom)) {
    return false;
  }
  return true;
};

const VerifiyMobileNumber = () => {
  const [mobileNom, setMobilNom] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showOtpPopup, setShowOtpPopup] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const mobileNomIsValid = isValidMobileNom(mobileNom);
    setErrorMessage(mobileNomIsValid ? "" : "برجاء ادخال رقم الهاتف");
    const formattedPhoneNumber = splitMobileNumber(mobileNom);
    localStorage.setItem("mobileNumber", formattedPhoneNumber);
    if (mobileNomIsValid) {
      try {
        const response = await axios.post(
          `https://aircon-api.basilalasadi.com/api/SMS/SendOTP?phoneNumber=${formattedPhoneNumber}`,
          { phoneNumber: formattedPhoneNumber }
        );
        if (response.status === 200) {
          const id = response.data.id;
          localStorage.setItem("verificationId", id);
          setShowOtpPopup(true);
        } else {
          setErrorMessage("حدث خطأ أثناء التحقق من رقم هاتفك.");
        }
      } catch (error) {
        setErrorMessage(
          "حدث خطأ في الاتصال بالشبكة. يرجى المحاولة مرة أخرى لاحقًا."
        );
      }
    }
  };

  return (
    <section className="nomVerification">
      <div className="nomVerification-container container">
        <div className="intro-box">
          <h5>
            <FaRegUser /> تسجيل دخول
          </h5>
          <p>
            للحجز أضف رقم جوالك أدناه ، وسيتم ارسال رساله نصيه للتحقق من رقم
            الجوال المضاف ..
          </p>
        </div>
        <form onSubmit={submit}>
          <div className="inputBox">
            <input
              type="text"
              placeholder="5xxxxxxxx"
              onChange={(e) => setMobilNom(e.target.value)}
              value={mobileNom}
              dir="ltr"
            />
            <input
              className="text-center"
              type="text"
              value={"966+"}
              readOnly
            />
          </div>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
          <button type="submit">
            <FaMobileAlt />
            ارسال رمز التحقق
          </button>
        </form>
        {showOtpPopup && <OtpPage onClose={() => setShowOtpPopup(false)} />}
      </div>
    </section>
  );
};

export default VerifiyMobileNumber;
