import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import MobileNav from "../../components/mobileNav/MobileNav";
import "./cancelledRequests.css";

const CancelledRequests = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedForDelete, setSelectedForDelete] = useState([]);
  const [error, setError] = useState(null);

  const url = `https://aircon-api.basilalasadi.com/api/maintenance/GetCancelRequests`;

  const maxLength = 10;
  const maxLengthCity = 6;

  const getAllAppointments = async () => {
    try {
      const maintenanceAreaIdsString =
        localStorage.getItem("MaintenanceAreaIds");
      const maintenanceAreaIds = maintenanceAreaIdsString
        .split(",")
        .map(Number);
      const response = await axios.post(url, {
        maintenanceAreaIds: maintenanceAreaIds,
      });
      setAppointments(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  useEffect(() => {
    getAllAppointments();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <MobileNav />
      <section className="newAppointment cancelledRequests finished mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>الطلبات الملغيه</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/inProgress"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <div className="newAppointment-container">
            <div className="row">
              <div className="col-lg-2">
                <Sidebar />
              </div>
              <div
                className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col pt-4"
                ref={componentRef}
              >
                <div className="d-flex justify-content-between info">
                  <div className="title d-flex">
                    <h2 className="mx-2 mb-4">الطلبات الملغيه</h2>
                    <h3 className="mb-3">{appointments.length}</h3>
                  </div>
                  <div className="buttons">
                    <button className="printButton" onClick={handlePrint}>
                      طباعة التقرير
                    </button>
                  </div>
                </div>
                <div className="table-responsive-sm overflow-auto">
                  <table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th scope="row">رقم الطلب</th>
                        <th scope="row">إسم العميل</th>
                        <th scope="row">رقم الهاتف</th>
                        <th scope="row">المدينه</th>
                        <th scope="row">نوع المكيف</th>
                        <th scope="row">تاريخ الصيانه</th>
                        <th scope="row">الفني المسؤول</th>
                        <th scope="row" className="text-center">
                          التحكم
                        </th>
                      </tr>
                    </thead>
                    {appointments?.length > 0 ? (
                      <tbody>
                        {appointments.map((appointment) => (
                          <tr key={appointment.RequestId}>
                            <td>{appointment.FormattedRequestNumber}</td>
                            <td>
                              {appointment.CustomerName &&
                              appointment.CustomerName.length > maxLength
                                ? `${appointment.CustomerName.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : appointment.CustomerName}
                            </td>
                            <td>
                              <a
                                href={`https://api.whatsapp.com/send?phone=+966${appointment.PhoneNumber.replace(
                                  /[^\d+]/g,
                                  ""
                                )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                                target="__blank"
                              >
                                {appointment.PhoneNumber}
                              </a>
                            </td>
                            <td>
                              {appointment.City &&
                              appointment.City.length > maxLengthCity
                                ? `${appointment.City.substring(
                                    0,
                                    maxLengthCity
                                  )}...`
                                : appointment.City}
                            </td>
                            <td>
                              {appointment.ACType &&
                              appointment.ACType.length > maxLength
                                ? `${appointment.ACType.substring(
                                    0,
                                    maxLength
                                  )}...`
                                : appointment.ACType}
                            </td>
                            <td>{appointment.MaintenanceStartDate}</td>
                            <td>{appointment.TechnicianName}</td>
                            <td className="btn-box">
                              <Link
                                to={`/dashboard/inProgress/${appointment.RequestId}`}
                              >
                                <button>تفاصيل</button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="no-data-message">
                          <td colSpan="2">لا توجد بيانات لعرضها حاليا :(</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default CancelledRequests;
