import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "./scheduled.css";
import MobileNav from "../../components/mobileNav/MobileNav";

const ScheduledProjects = (props) => {
  const { pendingLength } = props;

  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const maxLength = 10;

  const getScheduledProjects = async () => {
    try {
      var url =
        "https://aircon-api.basilalasadi.com/api/Reports/TechnicianRequests";
      await axios
        .post(url, {
          TechnicianId: params.scheduledProjectsId,
          statusId: 4,
          FromMaintenanceStartDate: fromDate,
          ToMaintenanceStartDate: toDate,
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const moveDataToCancelled = async (appointment, condition) => {
    try {
      const parsedData = JSON.parse(JSON.stringify(appointment));
      const requestId = parsedData.RequestId;
      Swal.fire({
        title: "هل أنت متأكد من الغاء الطلب؟",
        text: "لن تستطيع استعاده الطلب بعد الالغاء!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, move it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        backdrop: `rgba(0,0,123,0.4)`,
        customClass: {
          container: "my-swal",
        },
      }).then(async (willMove) => {
        if (willMove.value) {
          try {
            await axios.post(
              "https://aircon-api.basilalasadi.com/api/maintenance/CancelRequest",
              {
                RequestId: requestId,
                ProcessingTypeId: condition,
                UserId: localStorage.getItem("UserId"),
              }
            );
            toast.success("تم الغاء الطلب بنجاح", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setData(data.filter((d) => d.RequestId !== appointment.RequestId));
            getScheduledProjects();
          } catch (error) {
            console.error("Error moving request:", error);
          }
        } else if (willMove.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: "Cancelled",
            text: "request didn't move",
            icon: "info",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
        }
      });
    } catch (error) {
      console.error("Error parsing data:", error);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Report",
    onAfterPrint: () => console.log("print success"),
  });

  const handleSearch = async (e) => {
    e.preventDefault();
    await getScheduledProjects();
  };

  useEffect(() => {
    getScheduledProjects();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <MobileNav />
      <section className="newAppointment scheduled mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>طلبات قيد التنفيذ</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/newAppointment"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <>
            <div className="newAppointment-container">
              <div className="row">
                <div className="col-lg-2">
                  <Sidebar pendingLength={pendingLength} />
                </div>
                <div
                  className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col p-4"
                  ref={componentRef}
                >
                  <div className="d-flex info justify-content-between">
                    <div className="d-flex ">
                      <h2 className="mx-2 mb-0">طلبات قيد التنفيذ:</h2>
                      <h3>{data.length}</h3>
                    </div>
                    <button className="printButton" onClick={handlePrint}>
                      طباعه التقرير
                    </button>
                  </div>
                  <div className="form-holder">
                    <h5>ما الذي تبحث عنه؟</h5>
                    <form onSubmit={handleSearch} className="mt-4">
                      <h5>من</h5>
                      <input
                        type="date"
                        onChange={(e) => setFromDate(e.target.value)}
                        value={fromDate}
                      />
                      <h5>إلى</h5>
                      <input
                        type="date"
                        onChange={(e) => setToDate(e.target.value)}
                        value={toDate}
                      />
                      <button disabled={loading} type="submit">
                        {loading ? "جاري البحث..." : "بحث"}
                      </button>
                    </form>
                  </div>
                  <div className="table-responsive-sm overflow-auto">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="row">الاسم</th>
                          <th scope="row">رقم الهاتف</th>
                          <th scope="row">المنطقه</th>
                          <th scope="row">المدينه</th>
                          <th scope="row">المكيف</th>
                          <th scope="row">تاريخ الصيانه</th>
                          <th scope="row">اسم الفني</th>
                          <th scope="row" className="text-center">
                            التحكم
                          </th>
                        </tr>
                      </thead>
                      {data?.length > 0 ? (
                        <tbody>
                          {data.map((item) => (
                            <tr key={item.RequestId}>
                              <td>
                                {item.CustomerName &&
                                item.CustomerName.length > maxLength
                                  ? `${item.CustomerName.substring(
                                      0,
                                      maxLength
                                    )}...`
                                  : item.CustomerName}
                              </td>
                              <td>
                                <a
                                  href={`https://api.whatsapp.com/send?phone=+966${item.PhoneNumber.replace(
                                    /[^\d+]/g,
                                    ""
                                  )}&text=Hello%2C%20I'm%20interested%20in%20your%20product`}
                                  target="__blank"
                                >
                                  {item.PhoneNumber}
                                </a>
                              </td>
                              <td>
                                {item.Region && item.Region.length > maxLength
                                  ? `${item.Region.substring(0, maxLength)}...`
                                  : item.Region}
                              </td>
                              <td>
                                {item.City && item.City.length > maxLength
                                  ? `${item.City.substring(0, maxLength)}...`
                                  : item.City}
                              </td>
                              <td>
                                {item.ACType && item.ACType.length > maxLength
                                  ? `${item.ACType.substring(0, maxLength)}...`
                                  : item.ACType}
                              </td>
                              <td>{item.MaintenanceStartDate}</td>
                              <td>{item.TechnicianName}</td>
                              <td className="btn-box text-center">
                                <button
                                  onClick={() => moveDataToCancelled(item, 6)}
                                >
                                  الغاء
                                </button>
                                <Link
                                  className="link"
                                  to={`/dashboard/inProgress/${item.RequestId}`}
                                >
                                  <button>تفاصيل</button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr className="no-data-message">
                            <td colSpan="2">لا توجد بيانات لعرضها حاليا :(</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default ScheduledProjects;
