import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import Sidebar from "../sidebar/Sidebar";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./addSpareParts.css";
import MobileNav from "../mobileNav/MobileNav";

const isValidSparePart = (sparePart) => sparePart !== "";

const AddSpareParts = () => {
  const [sparePart, setSparePart] = useState([]);
  const [sparePartError, setSparePartError] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const url = `https://aircon-api.basilalasadi.com/api/AirCondMaintParts`;

  const submit = async (e) => {
    e.preventDefault();

    const sparePartIsValid = isValidSparePart(sparePart);

    setSparePartError(sparePartIsValid ? "" : "برجاء ادخال قطع الغيار");

    if (sparePartIsValid) {
      try {
        await axios.post(url, {
          Name: sparePart,
        });
        toast.success("تم اضافه قطع الغيار بنجاح", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getAllSpareParts();
        setSparePart("");
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const getAllSpareParts = async () => {
    try {
      const response = await axios.get(
        "https://aircon-api.basilalasadi.com/api/AirCondMaintParts"
      );
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteAcTypes = async (Id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
      backdrop: `rgba(0,0,123,0.4)`,
      customClass: {
        container: "my-swal",
      },
    }).then(async (willDelete) => {
      if (willDelete.value) {
        try {
          await axios.delete(
            `https://aircon-api.basilalasadi.com/api/AirCondMaintParts/${Id}`
          );
          Swal.fire({
            title: "Deleted!",
            text: "The AC type has been deleted successfully",
            icon: "success",
            confirmButtonText: "Cool",
            timer: 2000,
          });
          getAllSpareParts();
        } catch (error) {
          // Extract the error message from the response object
          let errorMessage;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
            console.log(errorMessage);
          } else {
            errorMessage = "An unexpected error occurred";
            console.log(errorMessage);
          }
          // Display the error message using SweetAlert2
          Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: "error",
            confirmButtonText: "Try Again",
          });
          setError(error.response?.data || {});
        }
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Your AC type is safe :)",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    });
  };

  useEffect(() => {
    getAllSpareParts();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <MobileNav />
      <section className="addSpareParts mt-0">
        <Helmet>
          <meta charSet="utf-8" />
          <title>إضافة قطع الغيار</title>
          <link
            rel="canonical"
            href="https://generalhome.sa/dashboard/addSpareParts"
          />
        </Helmet>
        {loading ? (
          <div className="spinner-container">
            <ClipLoader
              className="loader"
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="#00b1ff"
            />
          </div>
        ) : (
          <div className="addSpareParts-container">
            <div className="row">
              <div className="col-lg-2">
                {/*  Sidebar */}
                <Sidebar data={data} />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col pt-4">
                <form onSubmit={submit} className="addSparePartsForm">
                  <div className="acAddTypes">
                    <div className="inputBox">
                      <h6 htmlFor="acType">إضافة قطع الغيار:</h6>
                      <input
                        type="text"
                        placeholder="إسم القطعه"
                        value={sparePart}
                        onChange={(e) => setSparePart(e.target.value)}
                      />
                      {sparePartError && (
                        <p className="text-danger">{sparePartError}</p>
                      )}
                    </div>
                  </div>
                  <button>إضافة قطعة غيار</button>
                </form>
                <div className="seprateParts pt-5">
                  <div className="d-flex info">
                    <h2 className="mx-2 mb-4">إضافة قطع الغيار:</h2>
                    <h3 className="mb-3">{data.length}</h3>
                  </div>
                  <div className="table-responsive-sm">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="row">إسم قطعة الغيار</th>
                          <th scope="row" className="text-center">
                            التحكم
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((spareParts) => (
                          <tr key={spareParts.Id}>
                            <td>{spareParts.Name}</td>
                            <td className="text-center btn-holder">
                              <Link
                                to={`/dashboard/addSpareParts/${spareParts.Id}`}
                              >
                                تعديل
                              </Link>
                              <button
                                onClick={() => deleteAcTypes(spareParts.Id)}
                                className="mx-3"
                              >
                                حذف
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default AddSpareParts;
