import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./otpPage.css";

const OtpPage = ({ onClose }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState({
    digit1: "",
    digit2: "",
    digit3: "",
    digit4: "",
  });

  const handleOtpChange = (e, index) => {
    const newOtp = { ...otp };
    newOtp[`digit${index + 1}`] = e.target.value;
    setOtp(newOtp);
    if (e.target.value.length === 1) {
      const nextInput = document.querySelector(
        `.otp-input-container input:nth-child(${index + 2})`
      );
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    const enteredOtp = Object.values(otp).join("");
    if (enteredOtp === "") {
      setErrorMessage("برجاء ادخال رمز التحقق");
      return;
    }
    try {
      const storedId = localStorage.getItem("verificationId");
      const API = `https://aircon-api.basilalasadi.com/api/SMS/VerifyOTP?code=${enteredOtp}&id=${storedId}`;
      const response = await axios.post(API, {
        code: enteredOtp,
        id: storedId,
      });
      if (response.status === 200 && response.data.code === 1) {
        navigate("/appointment");
      } else {
        setErrorMessage("رمز التحقق غير صالح. يرجى المحاولة مرة أخرى.");
      }
    } catch (error) {
      setErrorMessage(
        "حدث خطأ في الاتصال بالشبكة. يرجى المحاولة مرة أخرى لاحقًا."
      );
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <div className="otp-popup-overlay">
        <section className="otpPage">
          <h2>رمز التحقق</h2>
          <div className="otpPage-container container">
            <p className="intro">الرجاء ادخال رمز التحقق المرسل لجوالك</p>
            <form onSubmit={submit}>
              <div className="otp-input-container" dir="ltr">
                <input
                  type="text"
                  maxLength="1"
                  value={otp.digit1}
                  onChange={(e) => handleOtpChange(e, 0)}
                  tabIndex={1}
                  className="otp-input"
                  autoFocus
                />
                <input
                  type="text"
                  maxLength="1"
                  value={otp.digit2}
                  onChange={(e) => handleOtpChange(e, 1)}
                  tabIndex={2}
                  className="otp-input"
                />
                <input
                  type="text"
                  maxLength="1"
                  value={otp.digit3}
                  onChange={(e) => handleOtpChange(e, 2)}
                  tabIndex={3}
                  className="otp-input"
                />
                <input
                  type="text"
                  maxLength="1"
                  value={otp.digit4}
                  onChange={(e) => handleOtpChange(e, 3)}
                  tabIndex={4}
                  className="otp-input"
                />
              </div>
              {errorMessage && <p className="error">{errorMessage}</p>}
              <div className="button-box d-flex align-items-center justify-content-center g-2">
                <button type="button" onClick={handleCancel}>
                  إلغاء
                </button>
                <button type="submit">إرسال</button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default OtpPage;
